import React, { Component } from "react";
import Slider from "react-slick";
import ScrollAnimation from "react-animate-on-scroll";

import { StaticImage } from "gatsby-plugin-image";

import "../../styles/partnerCarousel.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class PartnerCarousel extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 1200,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      arrows: true,
      dots: true,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <div
        className="carousel-wrapper"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="w-max-nf">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
            <h1 style={{ margin: "0" }}>Our Partners</h1>
          </ScrollAnimation>
          <br />
          <div style={{ padding: "2.5rem" }}>
            <Slider {...settings}>
              <div>
                <StaticImage
                  src="../../images/partners/exagrid.svg"
                  alt="Exagrid logo"
                  className="carousel-logo"
                  placeholder="blurred"
                />
                <br />
              </div>
              <div>
                <StaticImage
                  src="../../images/partners/microsoft.svg"
                  alt="microsoft logo"
                  className="carousel-logo"
                  placeholder="blurred"
                />
                <br />
              </div>
              <div>
                <StaticImage
                  src="../../images/partners/teraco.svg"
                  alt="teraco logo"
                  className="carousel-logo"
                  placeholder="blurred"
                />
                <br />
              </div>
              <div>
                <StaticImage
                  src="../../images/partners/veeam.svg"
                  alt="veeam logo"
                  className="carousel-logo"
                  placeholder="blurred"
                />
                <br />
              </div>
              <div>
                <StaticImage
                  src="../../images/partners/vmware.svg"
                  alt="vmware logo"
                  className="carousel-logo"
                  placeholder="blurred"
                />
                <br />
              </div>
              <div>
                <StaticImage
                  src="../../images/partners/rubrik.svg"
                  alt="rubrik logo"
                  className="carousel-logo"
                  placeholder="blurred"
                />
                <br />
              </div>
              <div>
                <StaticImage
                  src="../../images/partners/dell.svg"
                  alt="dell logo"
                  className="carousel-logo"
                  placeholder="blurred"
                />
                <br />
              </div>
              <div>
                <StaticImage
                  src="../../images/partners/ADP.png"
                  alt="dell logo"
                  className="carousel-logo"
                  placeholder="blurred"
                />
                <br />
              </div>
            </Slider>
          </div>

          <br />
          <br />
        </div>
        {/* <button>
          MORE FROM OUR PARTNERS <span>&#x279C;</span>
        </button> */}
      </div>
    );
  }
}
